<template>
	<div class="page-container">
		<nav-bar title="已推送学时及阶段查询" id="navbar" left-arrow @click-left="onClickLeft"></nav-bar>
		<van-tabs v-model="activeName" @click="onClickTab">
			<van-tab title="科目一" name="1">
				<van-cell-group inset title="科目一阶段学时审核结果" v-if="part1.pushStatus === 1">
					<van-cell title="推送状态" :value="part1.pushMsg" label="指阶段学时是否已正常上传" />
					<van-cell title="审核状态" :value="checkValidStatusFormatter(part1.validStatus)" />
					<van-cell title="送审时间" :value="part1.pushTime" />
					<van-cell title="复审时间" :value="part1.checkTime" />
					<van-cell title="复核结果" :value="isCheckFormatter(part1.isCheck)" />
					<van-cell title="终审时间" :value="part1.lastCheckTime" />
					<van-cell title="终审结果" :value="isLastCheckFormatter(part1.isLastCheck)" />
					<van-cell title="补学学时">
						<template #right-icon>
							<span style="color:#e10900; font-weight: bold;">{{ part1.needfulTime }}</span>
						</template>
					</van-cell>
					<van-button v-if="part1.needfulTime > 0" type="warning" block @click="onClickUploadPeriod(1)">我已确认补足学时，申请阶段学时上传</van-button>
				</van-cell-group>
				<van-empty v-if="part1.pushStatus === 0" :description="'科目一阶段学时未推送，请您确认通过审核有效学时总计达到' + part1.periodTotal + '分钟'" /> 
				<van-cell-group inset title="科目一审核学时记录" v-if="part1.classRecord.length > 0">
					<template>
						<div style="margin: 10px 0;">
							<van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" wrapable :scrollable="false">
								审核后总计有效学时不够{{part1.periodTotal}}分钟的，请在开始学习页面点击"学时不够，请点这里"，继续学习补齐{{part1.periodTotal}}分钟，系统方可自动提交该阶段学时。
							</van-notice-bar>
							<van-row style="border-bottom: 1px #ccc solid;">
								<van-col span="16">时间</van-col>
								<van-col span="3">学时</van-col>
								<van-col span="3">状态</van-col>
								<van-col span="2"></van-col>
							</van-row>
							<van-row v-for="(item, index) in part1.classRecord" :key="index">
								<van-col span="16">{{ item.starttime }}-{{ item.endtime }}</van-col>
								<van-col span="3">{{ item.submitDuration }}</van-col>
								<van-col span="3">
									<van-tag type="success" v-if="item.status === 1">通过</van-tag>
									<van-tag type="danger" v-if="item.status !== 1">无效</van-tag>
								</van-col>
								<van-col span="2"><van-icon name="question-o" color="#ee0a24" v-if="item.status !== 1 && item.deductionreson !== ''" @click="showMsg(item)" /></van-col>
							</van-row>
							<van-row style="border-top: 1px #ccc solid;">
								<van-col span="16">以上合计通过/无效学时</van-col>
								<van-col span="6"><span style="color:#07c160;">{{ part1.passPeriod }}</span>/<span style="color:#ee0a24;">{{ part1.invalidPeriod }}</span></van-col>
								<van-col span="2"></van-col>
							</van-row>
							<van-row style="border-top: 1px #ccc solid;" v-if="part1.validTime">
								<van-col span="16">审核后总计有效学时</van-col>
								<van-col span="3"><span style="color:#E10900; font-weight: bold;">{{ part1.validTime }}</span></van-col>
								<van-col span="3"></van-col>
								<van-col span="2"></van-col>
							</van-row>
						</div>
					</template>
				</van-cell-group>
				<van-empty v-if="part1.classRecord.length === 0" description="您还没有科目一电子教学日志推送记录" />
			</van-tab>
			<van-tab title="科目四" name="4">
				<van-cell-group inset title="科目四阶段学时审核结果" v-if="part4.pushStatus === 1">
					<van-cell title="推送状态" :value="part4.pushMsg" label="指阶段学时是否已正常上传" />
					<van-cell title="审核状态" :value="checkValidStatusFormatter(part4.validStatus)" />
					<van-cell title="送审时间" :value="part4.pushTime" />
					<van-cell title="复审时间" :value="part4.checkTime" />
					<van-cell title="复核结果" :value="isCheckFormatter(part4.isCheck)" />
					<van-cell title="终审时间" :value="part4.lastCheckTime" />
					<van-cell title="终审结果" :value="isLastCheckFormatter(part4.isLastCheck)" />
					<van-cell title="补学学时">
						<template #right-icon>
							<span style="color:#e10900; font-weight: bold;">{{ part4.needfulTime }}</span>
						</template>
					</van-cell>
					<van-button v-if="part4.needfulTime > 0" type="warning" block @click="onClickUploadPeriod(4)">我已确认补足学时，申请阶段学时上传</van-button>
				</van-cell-group>
				<van-empty v-if="part4.pushStatus === 0" :description="'科目四阶段学时未推送，请您确认通过审核有效学时总计达到' + part4.periodTotal + '分钟'" />
				<van-cell-group inset title="科目四审核学时记录" v-if="part4.classRecord.length > 0">
					<template>
						<div style="margin: 10px 0;">
							<van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" wrapable :scrollable="false">
								审核后总计有效学时不够{{part4.periodTotal}}分钟的，请在开始学习页面点击"学时不够，请点这里"，继续学习补齐{{part4.periodTotal}}分钟，系统方可自动提交该阶段学时。
							</van-notice-bar>
							<van-row style="border-bottom: 1px #ccc solid;">
								<van-col span="16">时间</van-col>
								<van-col span="3">学时</van-col>
								<van-col span="3">状态</van-col>
								<van-col span="2"></van-col>
							</van-row>
							<van-row v-for="(item, index) in part4.classRecord" :key="index">
								<van-col span="16">{{ item.starttime }}-{{ item.endtime }}</van-col>
								<van-col span="3">{{ item.submitDuration }}</van-col>
								<van-col span="3">
									<van-tag type="success" v-if="item.status === 1">通过</van-tag>
									<van-tag type="danger" v-if="item.status !== 1">无效</van-tag>
								</van-col>
								<van-col span="2"><van-icon name="question-o" color="#ee0a24" v-if="item.status !== 1 && item.deductionreson !== ''" @click="showMsg(item)" /></van-col>
							</van-row>
							<van-row style="border-top: 1px #ccc solid;">
								<van-col span="16">以上合计通过/无效学时</van-col>
								<van-col span="6"><span style="color:#07c160;">{{ part4.passPeriod }}</span>/<span style="color:#ee0a24;">{{ part4.invalidPeriod }}</span></van-col>
								<van-col span="2"></van-col>
							</van-row>
							<van-row style="border-top: 1px #ccc solid;" v-if="part4.validTime">
								<van-col span="16">审核后总计有效学时</van-col>
								<van-col span="3"><span style="color:#E10900; font-weight: bold;">{{ part4.validTime }}</span></van-col>
								<van-col span="3"></van-col>
								<van-col span="2"></van-col>
							</van-row>
						</div>
					</template>
				</van-cell-group>
				<van-empty v-if="part4.classRecord.length === 0" description="您还没有科目四电子教学日志推送记录" />
			</van-tab>
		</van-tabs>
		<!-- loading 遮罩层 -->
		<van-overlay :show="showLoading" color="#1989fa">
			<div class="wrapper">
				<div class="block">
					<van-loading size="24px" vertical>
						<span style="color:#ff8506">{{ title || '加载中...' }}</span>
					</van-loading>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
import {NavBar, Toast} from "vant";
import {getUploadedPeriodInfo, uploadStagePeriod} from "@/api/user";
export default {
	name: "period-index",
	components: { NavBar },
	data() {
		return {
			title: '',
			showLoading: false,
			activeName: '',
			part1: {
				pushMsg: '',
				pushStatus: '',
				validStatus: '',
				pushTime: '',
				checkTime: '',
				isCheck: '',
				lastCheckTime: '',
				isLastCheck: '',
				validTime: '',
				needfulTime: '',
				classRecord: [],
				passPeriod: 0,
				invalidPeriod: 0,
				periodTotal: 0
			},
			part4: {
				pushMsg: '',
				pushStatus: '',
				validStatus: '',
				pushTime: '',
				checkTime: '',
				isCheck: '',
				lastCheckTime: '',
				isLastCheck: '',
				validTime: '',
				needfulTime: '',
				classRecord: [],
				passPeriod: 0,
				invalidPeriod: 0,
				periodTotal: 0
			}
		}
	},
	created() {
		let subject = this.$route.query.subject;
		this.getUploadedPeriodInfo(subject);
		this.activeName = subject;
	},
	methods: {
		onClickLeft() {
			this.$router.back();
		},
		onClickTab(name, title) {
			console.log(title);
			this.getUploadedPeriodInfo(name);
		},
		onClickUploadPeriod(subject) {
			this.$dialog.confirm({
				title: '系统提示',
				message: '请您确认已补足学时？\r\n否则请您继续学习补足学时',
			}).then(() => {
				this.uploadStagePeriod(subject);
			}).catch(() => {
				Toast.fail("您已经取消申请推送阶段学时，请继续学习");
			});
		},
		showMsg(o) {
			Toast.fail(o.deductionreson);
		},
		getUploadedPeriodInfo(subject) {
			this.showLoading = true;
			getUploadedPeriodInfo(subject).then(res => {
				if (subject === '1') {
					this.part1 = res;
				} else if (subject === '4') {
					this.part4 = res;
				}
				this.showLoading = false;
			}).catch(() => {
				this.showLoading = false;
			});
		},
		uploadStagePeriod(subject) {
			this.title = '申请中...'
			this.showLoading = true;
			uploadStagePeriod(subject).then(() => {
				this.showLoading = false;
				this.$dialog.alert({
					title: '系统提示',
					message: '申请已提交，请稍候进入本页面查看审核结果',
				}).then(() => {
					this.getUploadedPeriodInfo(subject);
				});
			}).catch(() => {
				this.showLoading = false;
				Toast.fail("阶段学时提交申请失败，请稍候重试或联系管理员");
			});
		},
		checkValidStatusFormatter (checkVal) {
			if (checkVal === 0) {
				return '不通过'
			} else if (checkVal === 1) {
				return '通过'
			} else {
				return '--'
			}
		},
		isCheckFormatter (val) {
			if (val === 0) {
				return '未复核'
			} else if (val === 1) {
				return '复核通过'
			} else if (val === 2) {
				return '复核不通过'
			} else {
				return '--'
			}
		},
		isLastCheckFormatter (val) {
			if (val === 0) {
				return '未终审'
			} else if (val === 1) {
				return '终审通过'
			} else if (val === 2) {
				return '终审不通过'
			} else {
				return '--'
			}
		},
	}
}
</script>

<style scoped>
#navbar {
	background-color: #ff8506;
}
#navbar /deep/ .van-ellipsis {
	color: #fff !important;
}
#navbar /deep/ .van-nav-bar__text {
	color: #fff !important;
}
#navbar /deep/ .van-icon {
	color: #fff !important;
}
.form-area {
	margin-top: 46px;
}
.title {
	margin: 0;
	padding: 16px 16px 16px;
	color: rgba(69, 90, 100, 0.6);
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
}
.total-num {
	font-weight: bold;
	font-family: Arial, Helvetica, sans-serif;
	color: #E10900;
}
.van-cell-group__title {
	background-color: #f5f5f5;
}
</style>
